import { ChangeEvent, FocusEventHandler } from 'react'
import { InputMask } from 'react-tiny-mask'
import { SxProps, TextField, Theme } from '@mui/material'

export interface MaskedInputProps {
  required?: boolean
  error?: boolean
  helperText?: string
  name: string
  id: string
  value: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  fullWidth?: boolean
  label: string
  type?: string
  mask: string | string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  size?: any
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined
  sx?: SxProps<Theme>
}

export default function MaskedInput({ mask, ...props }: MaskedInputProps) {
  return (
    <InputMask mask={mask} component={TextField} {...props} />
  )
}
