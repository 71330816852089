import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Grid, Typography } from "@mui/material"
import { usePlan } from "../../../../../Plans"
import { useForm } from "../../../../../Form"
import { ISettingsItem } from "../../../../../Settings"
import requests from "../../../../../api/client"
import SnackbarComponent from "../../../../SnackbarComponent"
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copyIcon.svg'

export default function PixCollapse({ name, variables }: ISettingsItem) {
  const { text = [] } = variables || {}
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { setFormState } = useForm()
  const { virtualChip, step, updatePlanState } = usePlan()
  const navigate = useNavigate()
  const pixData = JSON.parse(sessionStorage.getItem('pixData') || '{}')
  const pixCopyCode = pixData?.pixCopyCode
  const pixQrCode = pixData?.pixQrCode
  const pixClientId = pixData?.idClient

  const [timeLeft, setTimeLeft] = useState(() => {
    return Number(sessionStorage.getItem("timeLeft")) || 300
  })

  useEffect(() => {
    sessionStorage.setItem("timeLeft", timeLeft.toString())
  }, [timeLeft])

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(countdownInterval)
          sessionStorage.removeItem("timeLeft")
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(countdownInterval)
  }, [])

  useEffect(() => {
    const intervalRef = setInterval(async () => {
      try {
        const { pago } = await requests.pixAccept({ id: pixClientId, tipo: virtualChip ? "A" : "L" })
        if (pago) {
          clearInterval(intervalRef)
          clearTimeout(timeoutRef)
          sessionStorage.removeItem('timeLeft')
          sessionStorage.removeItem('pixData')
          navigate('/pedido-finalizado')
        } else {
          console.error("Aguardando pagamento!")
        }
      } catch (error) {
        console.error("Erro ao verificar Pix:", error)
      }
    }, 3000)

    const timeoutRef = setTimeout(() => {
      clearInterval(intervalRef)
      sessionStorage.removeItem('timeLeft')
      sessionStorage.removeItem('pixData')
      if (step === 4) {
        setFormState('disabledBtn', false)
        updatePlanState({ step: step - 1 })
        alert("Pix expirou! Realize uma nova compra.")
      }
    }, timeLeft * 1000)

    return () => {
      clearInterval(intervalRef)
      clearTimeout(timeoutRef)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(pixCopyCode)
      setOpenSnackbar(true)
    } catch (err) {
      console.error("Falha ao copiar!", err)
    }
  }

  const tips = [
    { title: text[0], description: text[1] },
    { title: text[2], description: text[3] },
    { title: text[4], description: text[5] }
  ]

  return (
    <Grid
      container
      className={name ? `${name} pix-collapse` : "pix-collapse"}
      sx={{ flexDirection: { md: 'row-reverse' }, mt: 2 }}
    >
      <Grid container item xs={12} md={6} sx={{ pl: { md: 2 } }}>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row', lg: 'column' }, alignItems: 'center' }}>
          <Grid
            item
            className="pixQrCodeBox"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: { xs: '12px 12px 0 0', md: '12px 12px 12px 12px' },
              width: '100%',
              py: 2,
              minHeight: 300
            }}
          >
            <Box component="img" onClick={handleCopyClick} src={pixQrCode} alt={'PIX QRCode'} sx={{ cursor: 'pointer', mt: { xs: -1 }, width: { xs: '60vw', md: 180, lg: 190, xl: 200 }, zIndex: { lg: 1000 } }} />
          </Grid>
          <Grid
            item
            className="pixQrCodeBox"
            sx={{
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: { xs: '0 0 12px 12px', md: '0 12px 12px 0', lg: '0 0 12px 12px' },
              width: '100%',
              height: { md: '100%', lg: 'unset' },
              mt: '-2px',
              py: 2
            }}
          >
            <Box
              onClick={handleCopyClick}
              sx={{
                alignItems: 'center',
                overflowWrap: 'break-word',
                maxWidth: { xs: 335, sm: 530, md: 145, lg: '50%', xl: 515 },
                cursor: 'pointer',
                mt: -1,
                ':hover': { opacity: 0.7 }
              }}
            >
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: pixCopyCode }} sx={{ cursor: 'pointer', maxWidth: { xs: 250, sm: 340, md: 370 }, mt: '10px' }} />
            </Box>
          </Grid>
        </Grid>
        <Grid container item sx={{ display: 'flex', justifyContent: 'space-between', mt: { xs: 1, md: 0.2, lg: 1 } }}>
          <Grid item onClick={handleCopyClick} sx={{ display: 'flex', alignItems: { xs: 'center', md: 'start' }, gap: 0.5, cursor: 'pointer' }}>
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: 'COPIAR' }} sx={{ fontSize: { xs: 12 } }} />
            <CopyIcon />
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: { xs: 'center', md: 'start' } }}>
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: `Tempo restante: ${timeLeft}s` }} sx={{ fontSize: { xs: 12 } }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} sx={{ justifyContent: 'center', gap: 4, mt: { xs: 5, md: 0 }, py: { md: 3 } }}>
        {tips.map(({ title, description }, index) => (
          <Grid key={index} container item className="stepByStep">
            <Grid item className="stepNumber" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', borderRadius: '27px', p: '5px 20px' }}>{index + 1}</Grid>
            <Grid item className="stepText" sx={{ display: 'flex', flexDirection: 'column', flex: '1', ml: 1.25, gap: 0.2 }}>
              <Typography variant="h6" dangerouslySetInnerHTML={{ __html: title }} sx={{ fontSize: { xs: 14 }, fontWeight: '500' }} />
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: description }} sx={{ fontSize: { xs: 12 } }} />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <SnackbarComponent open={openSnackbar} setOpen={setOpenSnackbar} text="Pix copiado!" />
    </Grid>
  )
}