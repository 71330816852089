import { Grid, Typography } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"
import CheckboxDefault from '../../CheckboxDefault'

export default function SelectPickUpChip({ name, variables }: ISettingsItem) {
  const { updatePlanState, pickUpChip, chipOnly } = usePlan()
  const { chipInfo: { shippingPrice, shippingPriceOTP } } = useInitReq()
  const { text = [] } = variables || {}
  const parsedShippingPrice = (parseFloat(chipOnly ? (shippingPriceOTP ?? "0.00") : (shippingPrice ?? "0.00")) || 0).toFixed(2).replace('.', ',')

  const handlePickUpChange = (pickUp: boolean) => {
    updatePlanState({ pickUpChip: pickUp })
  }

  return (
    <Grid
      container
      item
      className={name ? `${name} select-pick-up-chip` : "select-pick-up-chip"}
      xs={12}
    >
      <Grid container item className="pickUpChoose" xs={12}>
        <Grid container item className="pickUpChoice" xs={12}>
          <Grid container item className="checkArea">
            <CheckboxDefault
              checked={!pickUpChip}
              onChange={() => handlePickUpChange(false)}
            />
            <Grid item className="textAreaChipInfo">
              <Typography variant="h3" dangerouslySetInnerHTML={{ __html: text[0] }} />
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: text[1] }} />
            </Grid>
          </Grid>
          <Grid item className="priceAreaChipInfo">
            <Typography variant="h3" dangerouslySetInnerHTML={{ __html: `+ R$ ${parsedShippingPrice}` }} />
          </Grid>
        </Grid>

        <Grid container item className="pickUpChoice" xs={12}>
          <Grid container item className="checkArea">
            <CheckboxDefault
              checked={pickUpChip}
              onChange={() => handlePickUpChange(true)}
            />
            <Grid item className="textAreaChipInfo">
              <Typography variant="h3" dangerouslySetInnerHTML={{ __html: text[2] }} />
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: text[3] }} />
            </Grid>
          </Grid>
          <Grid item className="priceAreaChipInfo">
            <Typography variant="h3" dangerouslySetInnerHTML={{ __html: `+ R$ 0,00` }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}