import { useEffect } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import { useInitReq } from '../InitReq'
import { usePlan } from '../Plans'

export default function NumberCounter() {
  const { chipInfo: { chipPriceOTP, shippingPriceOTP, chipPriceNVMO } } = useInitReq()
  const { updatePlanState, chipQuantity } = usePlan()

  const handleIncrement = () => {
    updatePlanState({ chipQuantity: Math.min(chipQuantity + 1, 15) })
  }

  const handleDecrement = () => {
    updatePlanState({ chipQuantity: Math.max(chipQuantity - 1, 1) })
  }

  useEffect(() => {
    if (chipPriceOTP && shippingPriceOTP && chipPriceNVMO) {
      const totalChipPrice = (parseFloat(chipPriceOTP) + parseFloat(chipPriceNVMO))

      updatePlanState({
        chipOnlyPrice: (chipQuantity * totalChipPrice) + parseFloat(shippingPriceOTP)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chipQuantity])

  return (
    <Box className="numberCounter">
      <IconButton className="counterIcon" onClick={handleDecrement} disabled={chipQuantity === 1}>
        <Remove sx={{ width: "80%", height: "80%" }} />
      </IconButton>
      <Typography className="counterText" variant="body1" textAlign="center">
        {chipQuantity}
      </Typography>
      <IconButton className="counterIcon" onClick={handleIncrement} disabled={chipQuantity === 15}>
        <Add sx={{ width: "80%", height: "80%" }} />
      </IconButton>
    </Box>
  )
}