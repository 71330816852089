import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import requests from "./api/client"

interface UserConfigParamProps {
  id: string | number
  tipo: string
}

export interface StatesListProps {
  estado?: string,
  uf?: string,
  label?: string,
}

interface LogisticPlans {
  id: string
  plano: string
  valor: string
  repasse_mvno_ativacao_chip_fisico: string
  repasse_mvno_ativacao_chip_esim: string
  repasse_mvno_logistica_chip_plano: string
} 

export interface LogisticProps {
  habilitar_venda_somente_chip?: boolean,
  valor_chip?: string,
  valor_frete?: string
  valor_chip_avulso?: string,
  valor_frete_avulso?: string
  repasse_mvno_logistica_chip_avulso?: number
  habilitar_retirada_na_mvno?: boolean
  planos?: LogisticPlans[]
}

export interface ChipInfo {
  chipOnlySale?: boolean
  chipPrice?: string
  shippingPrice?: string
  chipPriceOTP?: string
  shippingPriceOTP?: string
  chipPriceNVMO?: string
  pickUpInStore?: boolean
  logisticPlans?: LogisticPlans[]
}

export interface UserConfigsProps {
  nome: string
  plano: string
  valor: string
  periodo: string
  esim: boolean
  esim_pdf: string
  portabilidade: boolean
}

export interface Requests {
  virtualStock: boolean
  physicalStock: boolean
  loadingVirtual: boolean
  loadingPhysical: boolean
  statesList: StatesListProps[]
  chipInfo: ChipInfo
  loadingChipReq: boolean
  currentUserConfigs: UserConfigsProps | null
  loadingUserConfigs: boolean
  userConfigParam: UserConfigParamProps
  ip: string
}

const defaultRequestsData: Requests = {
  virtualStock: false,
  physicalStock: false,
  loadingVirtual: true,
  loadingPhysical: true,
  statesList: [],
  chipInfo: {},
  loadingChipReq: true,
  currentUserConfigs: null,
  loadingUserConfigs: false,
  userConfigParam: {id: 0, tipo: ''},
  ip: ''
}

const RequestsContext = createContext<Requests>(defaultRequestsData)

export function useInitReq() {
  return useContext(RequestsContext)
}

export function InitReqProvider({ children }: PropsWithChildren) {
  const [currentPath, setCurrentPath] = useState<string>()
  const [virtualStock, setVirtualStock] = useState<boolean>(false)
  const [physicalStock, setPhysicalStock] = useState<boolean>(false)
  const [loadingVirtual, setLoadingVirtual] = useState<boolean>(true)
  const [loadingPhysical, setLoadingPhysical] = useState<boolean>(true)
  const [statesList, setStatesList] = useState<StatesListProps[]>([])
  const [chipInfo, setChipInfo] = useState<ChipInfo>({})
  const loadingChipReq = useRef(true)
  const [currentUserConfigs, setCurrentUserConfigs] = useState<UserConfigsProps | null>(null)
  const [loadingUserConfigs, setLoadingUserConfigs] = useState<boolean>(false)
  const [userConfigParam, setUserConfigParam] = useState<UserConfigParamProps>({id: 0, tipo: ''})
  const [ip, setIp] = useState<string>('')
  const location = useLocation()
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')
    const tipo = queryParams.get('tipo')
    const param = location.pathname.split('/')[1]
    
    if (id && tipo) setUserConfigParam({id, tipo})
    setCurrentPath(param)
  }, [location, currentPath])

  useEffect(() => {
    async function fetchIp() {
      try {
        const response = await requests.currentIp()
        setIp(response)
      } catch (error) {
        console.error('Erro ao inicializar requisições:', error)
      }
    }

    async function fetchVirtualStock() {
      try {
        const stock = await requests.virtualStock()
        setVirtualStock(stock > 0 ? true : false)
        setLoadingVirtual(false)
      } catch (error) {
        console.error('Erro ao inicializar requisições:', error)
      }
    }

    async function fetchPhysicalStock() {
      try {
        const stock = await requests.physicalStock()
        setPhysicalStock(stock > 0 ? true : false)
        setLoadingPhysical(false)
      } catch (error) {
        console.error('Erro ao inicializar requisições:', error)
      }
    }
    
    async function fetchStates() {
      try {
        const response = await requests.statesList()
        setStatesList(response)
      } catch (error) {
        console.error('Erro ao inicializar requisições:', error)
      }
    }

    async function fetchChip() {
      try {
        const {
          habilitar_venda_somente_chip,
          valor_chip,
          valor_frete,
          valor_chip_avulso,
          valor_frete_avulso,
          repasse_mvno_logistica_chip_avulso,
          habilitar_retirada_na_mvno,
          planos
        } = await requests.logistic()
        setChipInfo({
          chipOnlySale: habilitar_venda_somente_chip,
          chipPrice: valor_chip,
          shippingPrice: valor_frete,
          chipPriceOTP: valor_chip_avulso,
          shippingPriceOTP: valor_frete_avulso,
          chipPriceNVMO: `${repasse_mvno_logistica_chip_avulso}`,
          pickUpInStore: habilitar_retirada_na_mvno,
          logisticPlans: planos
        })
        loadingChipReq.current = false
      } catch (error) {
        console.error('Erro ao inicializar requisições:', error)
      }
    }

    async function fetchUserConfigs() {
      setLoadingUserConfigs(true)
      
      if (userConfigParam) {
        try {
          const userConfigs = await requests.userConfigs(userConfigParam)
          if (userConfigs) {
            setCurrentUserConfigs(userConfigs)
          } else {
            console.error("Resposta inesperada da API:", userConfigs)
          }
        } catch (error) {
          console.error("Erro ao buscar configurações de usuário:", error)
        } finally {
          setLoadingUserConfigs(false)
        }
      }
    }

    if (currentPath === "configure-sua-conta") fetchUserConfigs()
    if (currentPath === '' || currentPath === 'vip') {
      fetchVirtualStock()
      fetchPhysicalStock()
      fetchStates()
      fetchChip()
      fetchIp()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath])

  return (
    <RequestsContext.Provider
      value={{
        virtualStock,
        physicalStock,
        loadingVirtual,
        loadingPhysical,
        statesList,
        chipInfo,
        loadingChipReq: loadingChipReq.current,
        currentUserConfigs,
        loadingUserConfigs,
        userConfigParam,
        ip
      }}
    >
      {children}
    </RequestsContext.Provider>
  )
}