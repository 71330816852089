import { Fragment } from 'react'
import { Box, Snackbar, IconButton, SxProps, Theme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface SnackbarComponentProps {
  open: boolean
  setOpen: (open: boolean) => void
  text: string
  sx?: SxProps<Theme>
}

export default function SnackbarComponent({ open, setOpen, text, sx }: SnackbarComponentProps) {
  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  )

  return (
    <Box sx={{ ...sx }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message={text}
        action={action}
      />
    </Box>
  )
}