import { ElementType, useEffect, useRef } from "react"
import { Grid, TextField } from "@mui/material"
import { DateField } from '@mui/x-date-pickers/DateField'
import MaskedInput, { MaskedInputProps } from "../../../../MaskedInput"
import { useForm } from "../../../../../Form"
import { ISettingsItem } from "../../../../../Settings"

export interface FieldProps extends Omit<MaskedInputProps, "mask"> {
  Component: ElementType
  mask?: string | string[]
  clearable?: boolean
  format?: string
  multiline?: boolean
}

export default function CardCollapse({ name, variables }: ISettingsItem) {
  const { currentFields, formState: { showError }, setCurrentFields, setFormState, setModelledField } = useForm()
  const { cardName, cardNumber, cardIssueDate, cardSecurityCode } = currentFields
  const fieldsUnfilled = useRef([] as string[])
  const { text = [] } = variables || {}

  const fields: FieldProps[] = [
    {
      Component: TextField,
      required: true,
      helperText: "Campo obrigatório!",
      name: "cardName",
      id: "outlined-cardName-input",
      value: cardName,
      onChange: setCurrentFields,
      label: text[1],
      type: "name",
    },
    {
      Component: MaskedInput,
      required: true,
      helperText: "Campo obrigatório!",
      name: "cardNumber",
      id: "outlined-cardNumber-input",
      value: cardNumber,
      onChange: setCurrentFields,
      label: text[0],
      type: "tel",
      mask: "#### #### #### ####",
    },
    {
      Component: DateField,
      required: true,
      helperText: "Campo obrigatório!",
      name: "cardIssueDate",
      id: "outlined-cardIssueDate-input",
      value: cardIssueDate,
      onChange: value => value && setModelledField("cardIssueDate", value),
      label: text[3],
      clearable: true,
      format: "MM/YYYY",
    },
    {
      Component: MaskedInput,
      required: true,
      helperText: "Campo obrigatório!",
      name: "cardSecurityCode",
      id: "outlined-tel-input",
      value: cardSecurityCode,
      onChange: setCurrentFields,
      label: text[4],
      type: "tel",
      mask: "####",
    }
  ]

  const renderInputs = ({
    Component,
    required,
    helperText,
    name,
    id,
    value,
    onChange,
    label,
    type,
    mask,
    clearable,
    format,
  }: FieldProps, index: number, mob: boolean) => {
    return <Component
      key={index}
      required={required}
      error={showError && fieldsUnfilled.current.includes(name) && required}
      helperText={showError && fieldsUnfilled.current.includes(name) && helperText}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      fullWidth
      label={label}
      type={type}
      mask={mask}
      clearable={clearable}
      format={format}
      size={mob ? 'medium' : 'small'}
      sx={{
        '& .MuiInputBase-input': {
          color: 'black',
        }, '& .MuiFormLabel-root': {
          color: 'black',
        },
        '& label.Mui-focused': {
          color: 'black',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: '0',
          }
        }
      }}
    />
  }

  useEffect(() => {
    fieldsUnfilled.current = fields
      .filter(field => field.required)
      .reduce(
        (agg: string[], { name, value }) => {
          if (["", null, undefined].includes(value)) agg.push(name)
          return agg
        }, []
      )

    setFormState('form3', (fieldsUnfilled.current.length === 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardName, cardNumber, cardIssueDate, cardSecurityCode])

  return (
    <Grid container className={name ? `${name} card-collapse` : "card-collapse"}>
      <Grid item className="formArea" xs={12} sx={{ display: 'flex', flexDirection: 'column', mt: 2, gap: 2 }}>
        {fields.slice(0, 2).map((field, index) => renderInputs(field, index, true))}
        <Grid sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 2 }}>
          <Grid sx={{ flex: '1' }}>
            {renderInputs(fields[2], 2, true)}
          </Grid>
          <Grid sx={{ flex: '1' }}>
            {renderInputs(fields[3], 3, true)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}