import { useEffect } from "react"
import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"

export default function ChosenPlanChip({ name, variables }: ISettingsItem) {
  const {
    currentPlan,
    virtualChip,
    planIndex,
    monthlyPrice,
    annualPrice,
    chosenPlan,
    chipOnly,
    pickUpChip,
    chipQuantity,
    currentChipPrice,
    currentShippingPrice,
    updatePlanState,
    setvirtualChip
  } = usePlan()
  const {
    physicalStock,
    virtualStock,
    loadingVirtual,
    loadingPhysical,
    chipInfo: {
      chipPrice,
      chipPriceOTP,
      shippingPrice,
      shippingPriceOTP,
      chipPriceNVMO,
      logisticPlans,
    }
  } = useInitReq()
  const defaultPlanLabelArray = ['Start', 'Flexi', 'Mega', 'Ultra', 'Tour']
  const defaultChipTextArray = ['Pagamento Único', 'Entrega Digital']
  const { text } = variables || {}
  const planLabelArray = text || defaultPlanLabelArray
  const chipTextArray = defaultChipTextArray
  const currentChosenPlan = currentPlan.plano.replace(' ', '')
  const planLabel = planLabelArray[planIndex] || ''
  const chosenPlanLabel = chosenPlan === 'monthly' ? 'Pagamento mensal' : 'Pagamento à vista'

  useEffect(() => {
    if (!loadingVirtual && !loadingPhysical) {
      if (!physicalStock && virtualStock) setvirtualChip(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loadingVirtual, loadingPhysical, physicalStock, virtualChip])

  useEffect(() => {
    if (!chipOnly) {
      const planChipPrice = logisticPlans?.find(({ id }) => id === `${currentPlan.id}`)
      const parsedChipPriceNVMO = parseFloat(planChipPrice?.repasse_mvno_logistica_chip_plano ?? "0.00") || 0
      const parsedEsimPriceNVMO = parseFloat(planChipPrice?.repasse_mvno_ativacao_chip_esim ?? "0.00") || 0
      const chipPriceTotal = virtualChip ? parsedEsimPriceNVMO : parseFloat(chipPrice ?? "0.00") + parsedChipPriceNVMO
      const shippingPriceTotal = !pickUpChip ? parseFloat(shippingPrice ?? "0.00") : 0
  
      updatePlanState({
        currentChipPrice: chipPriceTotal.toFixed(2).replace('.', ','),
        currentShippingPrice: shippingPriceTotal.toFixed(2).replace('.', ','),
      })
    } else {
      const parsedChipPriceOTP = parseFloat(chipPriceOTP ?? "0.00") || 0
      const parsedChipPriceNVMO = parseFloat(chipPriceNVMO ?? "0.00") || 0
      const chipPriceTotal = (parsedChipPriceOTP + parsedChipPriceNVMO) * chipQuantity
      const shippingPriceTotal = !pickUpChip ? parseFloat(shippingPriceOTP ?? "0.00") : 0
  
      updatePlanState({
        currentChipPrice: chipPriceTotal.toFixed(2).replace('.', ','),
        currentShippingPrice: shippingPriceTotal.toFixed(2).replace('.', ','),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ chipOnly, chipQuantity, chipPrice, chipPriceOTP, chipPriceNVMO, shippingPrice, shippingPriceOTP, logisticPlans, currentPlan.id, virtualChip, pickUpChip ])
  
  const planPrice = () => {
    const currentPricePlan = chosenPlan === 'monthly' ? monthlyPrice : chosenPlan === 'annual' ? annualPrice * 12 : 0
    return (currentPricePlan).toFixed(2).replace('.', ',')
  }

  return (
    <Grid container item className={name ? `${name} chosen-plan-chip` : "chosen-plan-chip"} xs={12}>
      {!chipOnly ? (
        <>
          <Grid container item className="chosenPlanChip chosenPlan">
            <Grid item className="textArea">
              <h3>{currentChosenPlan} {planLabel}</h3>
              <p>{chosenPlanLabel}</p>
            </Grid>
            <Grid item className="priceArea">
              <h3>R$ {planPrice()}</h3>
            </Grid>
          </Grid>
          <Grid container item className="chosenPlanChip chosenChip">
            {!virtualChip ? (
              <>
                <Grid container item className="physical chip">
                  <Grid item className="textArea">
                    <h3>Chip Físico</h3>
                    <p>{chipTextArray[0]}</p>
                  </Grid>
                  <Grid item className="priceArea">
                    <h3>R$ {currentChipPrice}</h3>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container item className="virtual chip">
                <Grid item className="textArea">
                  <h3>Chip Virtual</h3>
                  <p>{chipTextArray[1]}</p>
                </Grid>
                <Grid item className="priceArea">
                  <h3>R$ {currentChipPrice}</h3>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!virtualChip && (
            <Grid container item className="chosenPlanChip chosenChip">
              <Grid container item className="physical chip">
                <Grid item className="textArea">
                  <h3>Frete</h3>
                  <p>Envio imediato</p>
                </Grid>
                <Grid item className="priceArea">
                  <h3>R$ {currentShippingPrice}</h3>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid container item className="chosenPlanChip chosenPlan">
            <Grid item className="textArea">
              <h3>Valor Chip</h3>
              <p>{chipQuantity} {chipQuantity > 1 ? 'chips' : 'chip'}</p>
            </Grid>
            <Grid item className="priceArea">
              <h3>R$ {currentChipPrice}</h3>
            </Grid>
          </Grid>
          <Grid container item className="chosenPlanChip chosenChip">
            <Grid container item className="physical chip">
              <Grid item className="textArea">
                <h3>Frete</h3>
                <p>Envio imediato</p>
              </Grid>
              <Grid item className="priceArea">
                <h3>R$ {currentShippingPrice}</h3>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}