import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import { Grid, Button, CircularProgress } from "@mui/material"
import { ISettingsItem, useSettings } from "../../../Settings"
import { useForm } from "../../../Form"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"
import requests from "../../../api/client"
import { ReactComponent as RightArrow } from '../../../assets/icons/rightArrow.svg'

export default function PurchaseSummary({ name, variables }: ISettingsItem) {
  const [reqLoading, setReqLoading] = useState(false)
  const [totalPrice, setTotalPrice] = useState('')
  const { currentFields, formState: { form1, form2, form3, disabledBtn }, setFormState, setModelledField } = useForm()
  const { pageTitle } = useSettings()
  const { chipInfo: { chipPrice, shippingPrice }, ip } = useInitReq()
  const {
    currentPlan,
    chosenPlan,
    monthlyPrice,
    annualPrice,
    pickUpChip,
    virtualChip,
    chipOnly,
    step,
    chipQuantity,
    currentChipPrice,
    currentShippingPrice,
    updatePlanState,
  } = usePlan()
  const {
    cpfCnpj,
    date,
    externalId,
    email,
    tel,
    street,
    number,
    complement,
    neighborhood,
    cep,
    cityId,
    paymentMethod,
    keepCardChecked,
    cardName,
    cardNumber,
    cardIssueDate,
    cardSecurityCode,
  } = currentFields
  const defaultTextArray = ['Total desta compra', 'Continuar', 'Pagar']
  const { text = defaultTextArray } = variables || {}
  const navigate = useNavigate()

  useEffect(() => {
    const parsedChipPrice = parseFloat(currentChipPrice.replace(',', '.')) || 0
    const parsedShippingPrice = (virtualChip || pickUpChip) ? 0 : parseFloat(currentShippingPrice.replace(',', '.')) || 0
    const currentPricePlan = chosenPlan === 'monthly' ? monthlyPrice : chosenPlan === 'annual' ? annualPrice * 12 : 0
    const totalPrice = (currentPricePlan + parsedChipPrice + parsedShippingPrice).toFixed(2).replace('.', ',')
  
    setTotalPrice(totalPrice)
  }, [annualPrice, monthlyPrice, chosenPlan, virtualChip, chipPrice, shippingPrice, chipOnly, currentChipPrice, currentShippingPrice, pickUpChip])

  function cleanField(texto: string) { return texto.replace(/[^\d]/g, '') }

  let cpfOrCnpjField
  if (cpfCnpj.length === 18) {
    cpfOrCnpjField = { cnpj: cleanField(cpfCnpj) }
  } else {
    cpfOrCnpjField = { cpf: cleanField(cpfCnpj) }
  }

  let birthdayDate
  if (cpfCnpj.length === 18) {
    const formattedDate = dayjs(date).format('YYYY-MM-DD')
    birthdayDate = dayjs(formattedDate).isValid() ? formattedDate : undefined
  } else {
    birthdayDate = dayjs(date).format('YYYY-MM-DD')
  }

  /* CONFIGS DE ENVIO */
  const cardRegisterJson = {
    nome_titular: cardName,
    numero_cartao: cardNumber.replace(/ /g, ""),
    mes_ano_vencimento: dayjs(cardIssueDate).format('MM/YYYY'),
    cvv: cardSecurityCode
  }

  const buyJson = {
    metodo_pagamento: paymentMethod,
    link_indicacao: localStorage.getItem("VipParam"),
    token: undefined,
    ip,
    id_externo: externalId,
    id_plano: currentPlan.id,
    quantidade: 1,
    nome: currentFields.name,
    email,
    telefone: cleanField(tel),
    ...(birthdayDate && { data_nascimento: birthdayDate }),
    endereco: {
      rua: street,
      numero: number,
      complemento: complement,
      bairro: neighborhood,
      cep: cleanField(cep),
      id_cidade: cityId
    },
    sistema_origem: pageTitle,
    ...cpfOrCnpjField
  }

  const activationJson = {
    metodo_pagamento: paymentMethod,
    link_indicacao: localStorage.getItem("VipParam"),
    token: undefined,
    ...(paymentMethod === "CARTAO" && keepCardChecked && {
      recarga_automatica_cartao: currentPlan.id
    }),
    ip,
    id_externo: externalId,
    nome: currentFields.name,
    email,
    telefone: cleanField(tel),
    ...(birthdayDate && { data_nascimento: birthdayDate }),
    endereco: {
      rua: street,
      numero: number,
      complemento: complement,
      bairro: neighborhood,
      cep: cleanField(cep),
      id_cidade: cityId
    },
    chips: [
      {
        esim: "SIM",
        id_plano: currentPlan.id,
        ddd: cleanField(tel).substring(0, 2)
      }
    ],
    sistema_origem: pageTitle,
    ...cpfOrCnpjField
  }

  const chipOnlyJson = {
    metodo_pagamento: paymentMethod,
    link_indicacao: localStorage.getItem("VipParam"),
    token: undefined,
    ip,
    id_externo: externalId,
    id_plano: 88888,
    quantidade: chipQuantity,
    nome: currentFields.name,
    email,
    telefone: cleanField(tel),
    ...(birthdayDate && { data_nascimento: birthdayDate }),
    endereco: {
      rua: street,
      numero: number,
      complemento: complement,
      bairro: neighborhood,
      cep: cleanField(cep),
      id_cidade: cityId
    },
    sistema_origem: pageTitle,
    ...cpfOrCnpjField
  }

  async function fetchCardRegister() {
    try {
      const { retorno, mensagem, dados } = await requests.cardRegister(cardRegisterJson)
      return { retorno, mensagem, dados }
    } catch (error) {
      console.error('Erro na requisição de compra:', error)
    }
  }

  async function fetchPurchase() {
    try {
      const {
        retorno,
        mensagem,
        id,
        info,
        pix_QRCode,
        pix_copia_cola,
        cartao_de_credito,
        boleto_codigo_barras,
        boleto_link
      } = chipOnly ? (
        await requests.buyRegister(chipOnlyJson)
      ) : (
        virtualChip ?
          await requests.activationReq(activationJson)
          :
          await requests.buyRegister(buyJson)
      )
      if (retorno) {
        return {
          retorno,
          mensagem,
          id,
          info,
          pix_QRCode,
          pix_copia_cola,
          cartao_de_credito,
          boleto_codigo_barras,
          boleto_link
        }
      } else {
        return {}
      }
    } catch (error) {
      console.error('Erro na requisição de compra:', error)
    }
  }

  function VerifyPayment(status: boolean) {
    if (status) {
      navigate('/pedido-finalizado')
    } else {
      alert("Pagamento recusado!")
      console.error("Pagamento recusado!")
    }
  }

  async function saleSubmit() {
    try {
      if (paymentMethod === 'CARTAO') {
        if (form3) {
          setReqLoading(true)
          setFormState('showError', false)
          await fetchCardRegister().then((res) => {
            if (!virtualChip) buyJson.token = res?.dados.token
            else activationJson.token = res?.dados.token

            if (res?.retorno) {
              if (res?.dados.token) {
                fetchPurchase()
                  .then((res) => {
                    const currentId = virtualChip ? res?.info.id : res?.id
    
                    setModelledField('clientId', currentId)
                    VerifyPayment(res?.cartao_de_credito === "Pagamento Autorizado")
                  }).catch((error) => {
                    console.error(error)
                  }).finally(() => {
                    setReqLoading(false)
                  })
              }
            } else {
              setReqLoading(false)
              alert(`Falha no cadastro do cartão: ${res?.mensagem}`)
            }
          })

        } else {
          setFormState('showError', true)
          setReqLoading(false)
        }
      } else {
        setReqLoading(true)
        fetchPurchase()
          .then((res) => {
            if (res?.retorno) {
              const currentId = virtualChip ? res?.info.id : res?.id

              setModelledField('clientId', currentId)
              if (paymentMethod === 'PIX') {
                sessionStorage.setItem(
                  'pixData',
                  JSON.stringify({
                    idClient: currentId,
                    pixQrCode: res?.pix_QRCode,
                    pixCopyCode: res?.pix_copia_cola,
                    pixLoading: false
                  })
                )
                setFormState('disabledBtn', true)
              } else if (paymentMethod === 'BOLETO') {
                sessionStorage.setItem(
                  'boletoData',
                  JSON.stringify({
                    idClient: currentId,
                    boletoBardCode: res?.boleto_codigo_barras,
                    boletoLink: res?.boleto_link,
                    boletoLoading: false
                  })
                )
              }
              setReqLoading(false)
              updatePlanState({ step: step + 1 })
            } else {
              alert('Algo deu errado. Tente novamente!')
              setReqLoading(false)
            }
          }).catch((error) => {
            console.error(error)
          }).finally(() => {
            setReqLoading(false)
          })
      }
    } catch (error) {
      alert(`Falha na compra: ${error}`)
      setReqLoading(false)
    }
  }

  const handleNext = async () => {
    /* const hasStoredData = (key: string) => !!sessionStorage.getItem(key)
  
    if (step === 3) {
      if (paymentMethod === "PIX" && hasStoredData("pixData")) {
        updatePlanState({ step: step + 1 })
        setFormState("disabledBtn", true)
        return
      } 
      if (paymentMethod === "BOLETO" && hasStoredData("boletoData")) {
        updatePlanState({ step: step + 1 })
        setFormState("disabledBtn", true)
        return
      } 
      if (paymentMethod === "CARTAO") {
        updatePlanState({ step: step + 1 })
        return
      }
      await saleSubmit()
      return
    } */

    if (step === 3) {
      if (paymentMethod === "PIX" || paymentMethod === "BOLETO") {
        await saleSubmit()
        return
      }

      if (paymentMethod === "CARTAO") {
        updatePlanState({ step: step + 1 })
        return
      }
    }
  
    if (step === 4) {
      if (paymentMethod === "CARTAO") {
        saleSubmit()
        return
      }

      if (paymentMethod === "BOLETO") {
        navigate('/pedido-finalizado')
        return
      }
    }
  
    const nextStep = 
      step === 0 ||
      (step === 1 && form1 && form2) ||
      (step === 2 && form3) ||
      step === 3 ||
      paymentMethod === "CARTAO"
  
    setFormState("showError", !nextStep)
    if (nextStep) updatePlanState({ step: step + 1 })
  }
  

  return (
    <Grid container item className={name ? `${name} purchase-summary` : "purchase-summary"} xs={12}>
      <Grid item className="priceArea">
        <h3>R$ {totalPrice}</h3>
        <p>{text[0]}</p>
      </Grid>
      <Grid item className="buttonArea">
        {reqLoading ? (
          <CircularProgress />

        ) : (
          <Button
            className={disabledBtn ? 'disabled' : ''}
            onClick={handleNext}
            disabled={disabledBtn}
            style={{ textTransform: 'none', opacity: disabledBtn ? '0.8' : '1' }}
          >
            {step === 4 ? (paymentMethod === "BOLETO" ? "Finalizar" : text[2]) : text[1]}
            <RightArrow />
          </Button>
        )}
      </Grid>
    </Grid>
  )
}