import { ChangeEvent, useState } from "react"
import { Autocomplete, CircularProgress, SxProps, TextField, Theme } from "@mui/material"

interface AutocompleteProps {
  required?: boolean
  id: string
  options: any
  noOptionsText?: string
  value?: {id?: number, label?: string}
  isOptionEqualToValue?: (option: any, value: any) => boolean
  getOptionLabel?: (option: any) => string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name: string
  label: string
  helperText?: string
  error?: boolean
  size?: any
  loading?: boolean
  sx?: SxProps<Theme>
}

export default function AutocompleteInput({
  required,
  id,
  options,
  noOptionsText,
  value,
  getOptionLabel,
  onChange,
  name,
  label,
  helperText,
  error,
  size,
  loading,
  sx,
}: AutocompleteProps): JSX.Element {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleChange = (name: string) => (event: any, value: any) => {
    onChange({ ...event, currentTarget: { name, value: value?.id } })
    setIsFocused(false)
  }

  return (
    <Autocomplete
      aria-required={required}
      autoComplete={false}
      id={id}
      open={isFocused}
      value={value || null}
      getOptionLabel={getOptionLabel}
      options={options}
      noOptionsText={noOptionsText}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange(name)}
      size={size}
      loading={loading}
      sx={sx}
      disablePortal
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={helperText}
          name={name}
          label={label}
          autoComplete="off"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}