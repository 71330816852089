import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import mainPage from "./defaults/mainpage.json"
import blankPage from "./defaults/blankpage.json"

export interface ISettingsVariables {
  image?: string[]
  text?: string[]
}

export interface ISettingsItem {
  name?: string
  component?: string
  file?: string
  render?: boolean
  variables?: ISettingsVariables
}

export interface ISettingsSection {
  file?: string
  top?: ISettingsItem[]
  left?: ISettingsItem[]
  middle?: ISettingsItem[]
  right?: ISettingsItem[]
  bottom?: ISettingsItem[]
}

export interface ISettingsPage {
  route: string
  layout?: string
  header: ISettingsSection
  content: ISettingsSection
  footer: ISettingsSection
}

export interface ISettingsContext {
  loading: boolean
  app?: string
  pageTitle?: string
  favIcon?: string
  pages: ISettingsPage[]
}

const defaultContext: ISettingsContext = {
  loading: true,
  ...mainPage,
}

const SettingsContext = createContext<ISettingsContext>(defaultContext)

export function useSettings() {
  return useContext(SettingsContext)
}

export function SettingsProvider({ children }: PropsWithChildren) {
  const [currentState, setCurrentState] = useState<ISettingsContext>(defaultContext)

  useEffect(() => {
    const app = window.location.hostname
    let settings: Partial<ISettingsContext> = {}

    const fetchSettings = async () => {
      const settingsFile = `/assets/${app}/settings.json`

      try {
        const response = await fetch(settingsFile)
        const result = await response.json()

        settings = result

      } catch (error) {
        console.error(`Erro ao recuperar as configurações do arquivo ${settingsFile}`)
      }

      const pages = settings.pages?.map(({ route = "/", ...page }) => {
        return mergeSettings([
          currentState.pages.find(({ route: currentRoute }) => currentRoute === route) || blankPage,
          defaultContext.pages.find(({ route: defaultRoute }) => defaultRoute === route) || blankPage,
          { ...page, route },
        ])
      })

      const newState: ISettingsContext = {
        ...defaultContext,
        ...settings,
        pages: pages || defaultContext.pages,
        loading: false,
        app,
      }

      setCurrentState(newState)
    }

    fetchSettings()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SettingsContext.Provider value={currentState}>{children}</SettingsContext.Provider>
  )
}

function mergeSettings(settings: any[]): ISettingsPage {
  const level1 = ["header", "content", "footer"]
  const level2 = ["top", "left", "middle", "right", "bottom"]

  return settings.reduce((result, item) => {
    Object.keys(item).forEach((key) => {
      if (level1.includes(key)) {
        if (!result[key]) result[key] = item[key]

        Object.keys(item[key]).forEach((subkey) => {
          if (level2.includes(subkey)) {
            const currentElements = result[key][subkey] || []
            const newElements = item[key][subkey] || []
            result[key][subkey] = newElements.reduce((agg: any, element: any) => {
              const index = agg.findIndex(({ name }: { name: string }) => name === element.name)
              if (index >= 0) {
                agg[index] = element
              } else {
                agg.push(element)
              }
              return agg
            }, currentElements)
          } else {
            result[key][subkey] = item[key][subkey]
          }
        })
      } else {
        result[key] = item[key]
      }
    })
    return result as ISettingsPage
  }, {})
}
