import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from "../../../Plans"
import { useInitReq } from "../../../InitReq"
import PersonalInfo from "./Steps/PersonalInfo"
import ContactInfo from "./Steps/ContactInfo"
/* STEP 1 */
import ChoosePlanText from "../../page1/right/ChoosePlanText"
import SelectChipInfo from "../../page2/left/SelectChipInfo"
import SelectPickUpChip from "./SelectPickUpChip"
import SelectPlanDetails from "../../page1/right/SelectPlanDetails"
/* STEP 3 */
import AdressInfo from "./Steps/AdressInfo"
/* STEP 4 */
import PaymentStep from './Steps/PaymentStep'

// PICK UP CHIP TEXT
const pickUpChipTextArray = {
  text: [
    "Recebimento do chip físico",
    "Escolha como o chip chegará até você"
  ]
}

const pickUpVariables = {
  text: [
    'Receber em casa',
    'Receba o chip no seu endereço',
    'Retirar na loja',
    'Ver no mapa'
  ]
}

// ACCESS TECHNOLOGY TEXT
const accessTechnologyTextArray = {
  text: [
    "Tecnologia de acesso",
    "Como você vai se conectar"
  ]
}

// PORTABILITY TEXT
const portabilityTextArray = {
  text: [
    "Ganhe Bônus",
    "Veja como aumentar sua internet"
  ]
}

// PORTABILITY VARIABLES
const portabilityVariables = {
  "text": [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'none',
    '',
    '',
  ]
}

const paymentStepArray = {
  text: [
    // CARTÃO
    'Cartão de Crédito',
    'Aceitamos todos os cartões',
    'RECOMENDADO',
    'flex',
    'Número do Cartão',
    'Nome Completo',
    'CPF do Titular do Cartão',
    'Data de Vencimento',
    'Código de Segurança',
    // PIX
    'PIX',
    'Pague com o app do seu banco',
    'PAGUE COM SEU BANCO',
    'flex',
    'Abra o app do seu banco',
    'Você pode pagar com seu banco de preferência.',
    'Escanei ou digite a chave PIX',
    'Aponte a camera do seu celular para a imagem ao lado ou copie o código',
    'Pronto! Aguarde a confirmação',
    'Assim que confirmado você será direcionado para a próxima página',
    'EXPIRA EM: ',
    // BOLETO
    'Boleto Bancário',
    'Até 2 dias úteis',
    'Pague com boleto',
    'none',
    'Copie o código e pague com seu banco',
    'Você pode pagar com seu banco de preferência.',
    'Download PDF'
  ]
}

export default function Steps({ name }: ISettingsItem) {
  const { step, chipOnly } = usePlan()
  const { physicalStock, virtualStock } = useInitReq()

  const stepContent = [
    <Grid container item className="step step-1" xs={12}>
      {chipOnly ? (
        physicalStock && virtualStock && (
          <>
            <ChoosePlanText name="pick-up-chip-text" variables={pickUpChipTextArray} />
            <SelectPickUpChip name="chip-only-pick-up" variables={pickUpVariables} />
          </>
        )
      ) : (
        <>
          {(physicalStock && virtualStock) && (
            <>
              <ChoosePlanText name="access-technology-text" variables={accessTechnologyTextArray} />
              <SelectChipInfo />
            </>
          )}
          <ChoosePlanText name="portability-text" variables={portabilityTextArray} />
          <SelectPlanDetails name="select-portability" variables={portabilityVariables} />
        </>
      )}
    </Grid>,
    <Grid container item className="step step-2" xs={12}>
      <PersonalInfo />
      <ContactInfo />
    </Grid>,
    <Grid container item className="step step-3" xs={12}>
      <AdressInfo />
    </Grid>,
    <Grid container item className="step step-4" xs={12}>
      <PaymentStep variables={paymentStepArray} paymentStep={false} />
    </Grid>,
    <Grid container item className="step step-5" xs={12}>
      <PaymentStep variables={paymentStepArray} paymentStep={true} />
    </Grid>
  ]

  return (
    <Grid container item className={name ? `${name} steps` : "steps"}>
      {stepContent[step]}
    </Grid>
  )
}