import { Checkbox, CheckboxProps, styled } from '@mui/material'
import { ReactComponent as CheckedboxIcon } from '../assets/icons/checkedboxIcon.svg'
import { ReactComponent as CheckboxIcon } from '../assets/icons/checkboxIcon.svg'

const StyledCheckbox = styled(({ checkedIcon, uncheckedIcon, ...props }: CheckboxProps & { checkedIcon?: JSX.Element, uncheckedIcon?: JSX.Element }) => (
  <Checkbox
    checkedIcon={checkedIcon ?? <CheckedboxIcon />}
    icon={uncheckedIcon ?? <CheckboxIcon />}
    {...props}
  />
))(() => ({
  '&.MuiButtonBase-root.MuiCheckbox-root': {
    padding: '0 5px 1px 0',
  },
  '&.MuiButtonBase-root.MuiCheckbox-root svg': {
    width: '20px',
  },
}))

interface CheckboxDefaultProps {
  checked?: boolean
  checkedIcon?: JSX.Element
  uncheckedIcon?: JSX.Element
  onChange?: (checked: boolean) => void
}

export default function CheckboxDefault({ checked, checkedIcon, uncheckedIcon, onChange, ...props }: CheckboxDefaultProps) {
  return (
    <StyledCheckbox
      checked={checked}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      onChange={() => onChange && onChange(!checked)}
      {...props}
    />
  )
}
