import { useEffect } from 'react'
import { Grid } from '@mui/material'
import { ISettingsItem } from "../../../Settings"
import StepperDefault from '../../StepperDefault'
import { usePlan } from '../../../Plans'

export default function StepperPlan({ name, variables }: ISettingsItem) {
  const { step, updatePlanState } = usePlan()
  const defaultTextArray = [
    'Seu Plano',
    'Informações',
    'Endereço',
    'Método de pagamento',
    'Pagamento'
  ]
  const { text = defaultTextArray } = variables || {}

  useEffect(() => {
    updatePlanState({ steps: defaultTextArray })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid item className={name ? `${name} stepper-plan step-${step + 1}` : `stepper-plan step-${step + 1}`}>
      <StepperDefault step={step} steps={text} />
    </Grid>
  )
}