import { FunctionComponent, ReactElement, SVGProps } from "react"
import { Box, Checkbox, Collapse, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { ISettingsItem } from "../../../../Settings"
import { useForm } from "../../../../Form"
import { usePlan } from "../../../../Plans"
import CheckboxDefault from "../../../CheckboxDefault"
import { PixCollapse, CardCollapse, BoletoCollapse } from "./collapses"
import { ReactComponent as Mastercard } from '../../../../assets/icons/cardBrands/mastercard.svg'
import { ReactComponent as Visa } from '../../../../assets/icons/cardBrands/visa.svg'
import { ReactComponent as Elo } from '../../../../assets/icons/cardBrands/elo.svg'
import { ReactComponent as Hipercard } from '../../../../assets/icons/cardBrands/hipercard.svg'
import { ReactComponent as DinersClub } from '../../../../assets/icons/cardBrands/dinersClub.svg'
import { ReactComponent as AmericanExpress } from '../../../../assets/icons/cardBrands/americanExpress.svg'
import { ReactComponent as PixIcon } from '../../../../assets/icons/pixIcon.svg'
import { ReactComponent as BoletoIcon } from '../../../../assets/icons/boletoIcon.svg'
import { ReactComponent as Checked } from '../../../../assets/icons/cardChecked.svg'
import { ReactComponent as Unchecked } from '../../../../assets/icons/cardUnchecked.svg'

interface PaymentMethodCardsProps {
  name: "PIX" | "CARTAO" | "BOLETO"
  title: string
  description: string
  icons: FunctionComponent<SVGProps<SVGSVGElement>>[]
  detail: string
  detailVisibility: string
  collapse: ReactElement
  collapseIn: boolean
}

interface PaymentStepProps extends ISettingsItem {
  paymentStep: boolean
}

export default function PaymentStep({ name, variables, paymentStep }: PaymentStepProps) {
  const { currentFields, setModelledField } = useForm()
  const { virtualChip } = usePlan()
  const { text = [] } = variables || {}
  const { paymentMethod, keepCardChecked } = currentFields
  const pixData = JSON.parse(sessionStorage.getItem('pixData') || '{}')
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"))

  const handleKeepCardChange = () => {
    if (paymentMethod === "CARTAO") {
      setModelledField('keepCardChecked', !keepCardChecked)
    }
  }

  const handleCheckboxChange = (checkbox: "PIX" | "CARTAO" | "BOLETO") => {
    setModelledField('paymentMethod', checkbox)
  }

  const paymentMethodCards: PaymentMethodCardsProps[] = [
    {
      name: 'CARTAO',
      title: text[0],
      description: text[1],
      icons: [Mastercard, Visa, Elo, Hipercard, DinersClub, AmericanExpress],
      detail: text[2],
      detailVisibility: text[3],
      collapse: <CardCollapse variables={{text: text.slice(4, 9)}} />,
      collapseIn: paymentMethod === "CARTAO"
    },
    {
      name: 'PIX',
      title: text[9],
      description: text[10],
      icons: [PixIcon],
      detail: text[11],
      detailVisibility: text[12],
      collapse: <PixCollapse variables={{text: text.slice(13, 20)}} />,
      collapseIn: paymentMethod === "PIX" && !pixData.pixLoading
    },
    {
      name: 'BOLETO',
      title: text[20],
      description: text[21],
      icons: [BoletoIcon],
      detail: text[22],
      detailVisibility: text[23],
      collapse: <BoletoCollapse variables={{text: text.slice(24)}} />,
      collapseIn: paymentMethod === "BOLETO"
    }
  ]

  return (
    <Grid
      container
      item
      sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5, width: '100%' }}
      className={name ? `${name} payment-step` : "payment-step"}
    >
      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: { xs: '100%', sm: '90%', md: '100%', lg: '80%' } }}>
        {paymentMethodCards
          .filter(({ name }) => !paymentStep || name === paymentMethod)
          .map(({
            name,
            title,
            description,
            icons,
            detail,
            detailVisibility,
            collapse,
            collapseIn
          }, index) => (
            <Grid key={`${index}-${paymentStep}`}>
              <Grid
                container
                className={paymentMethod === name ? 'paymentCard activated' : 'paymentCard disabled'}
                onClick={() => handleCheckboxChange(name)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: '12px',
                  p: { xs: '20px', md: '10px', lg: '20px' }
                }}
              >
                <Grid item className="cardCheckbox" sx={{ mr: 1 }}>
                  <CheckboxDefault checked={paymentMethod === name} checkedIcon={<Checked />} uncheckedIcon={<Unchecked />} />
                </Grid>
                <Grid item sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                  <Grid item>
                    <Grid sx={{ display: 'flex', width: '100%' }}>
                      <Typography
                        variant="h6"
                        dangerouslySetInnerHTML={{ __html: title }}
                        sx={{ fontSize: { xs: 16 }, alignSelf: 'center' }}
                      />
                      <Box
                        className="cardDetail"
                        sx={{
                          display: detailVisibility,
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'center',
                          fontSize: { xs: 10 },
                          fontWeight: '600',
                          color: 'white',
                          padding: '2px 6px',
                          ml: 1.25,
                          borderRadius: '5px'
                        }}>{detail}</Box>
                    </Grid>
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{ __html: description }}
                      sx={{ fontSize: { xs: 12 } }}
                    />
                  </Grid>
                  {name === 'CARTAO' && paymentStep && virtualChip && (
                    <Grid className="keepCard" sx={{ display: 'flex', alignItems: 'center', mt: { xs: 1 } }}>
                      <Checkbox
                        color="secondary"
                        icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                        checked={keepCardChecked}
                        onChange={handleKeepCardChange}
                        disabled={paymentMethod === "PIX"}
                        sx={{ p: 0, pr: { md: 0.5 }, '& svg': { width: { xs: '75%' } }, '&.MuiButtonBase-root.MuiCheckbox-root.Mui-disabled': { opacity: 0.3 } }}
                      />
                      <Typography variant="body2" sx={{ textAlign: 'center', fontSize: { xs: 10, md: 12 } }} dangerouslySetInnerHTML={{ __html: 'Manter recorrência no cartão de crédito' }} />
                    </Grid>
                  )}
                </Grid>
                {(name !== 'CARTAO' || lgUp) && (
                  <Grid item className={`${name}-card-icons`}>
                    {icons.map((Icon, index) => <Icon key={index} />)}
                  </Grid>
                )}
              </Grid>
              {paymentStep && (
                <Collapse className="collapses" in={collapseIn}>
                  {collapse}
                </Collapse>
              )}
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  )
}