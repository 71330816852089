import { Collapse, Divider, Grid, Typography } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"
import CheckboxDefault from '../../CheckboxDefault'
import SelectPickUpChip from "./SelectPickUpChip"

export default function SelectChipInfo({ name, variables }: ISettingsItem) {
  const { updatePlanState, setvirtualChip, virtualChip, currentPlan } = usePlan()
  const { chipInfo: { chipPrice, logisticPlans, pickUpInStore } } = useInitReq()
  const { text } = variables || {}
  const planChipPrice = logisticPlans?.find(({ id }) => id === `${currentPlan.id}`)
  const parsedChipPriceNVMO = (parseFloat(chipPrice ?? "0.00") + parseFloat(planChipPrice?.repasse_mvno_logistica_chip_plano ?? "0.00") || 0).toFixed(2).replace('.', ',')
  const parsedEsimPriceNVMO = (parseFloat(planChipPrice?.repasse_mvno_ativacao_chip_esim ?? "0.00") || 0).toFixed(2).replace('.', ',')
  const defaultTextArray = [
    'CHIP Físico <span>(Compra Única)</span>',
    'Chip que você insere em seu aparelho',
    'Receber em casa',
    'Receba o chip no seu endereço',
    'Retirar na loja',
    'Retire o chip na loja mais perto',
    'CHIP Virtual',
    'Chip para celulares modernos (Ex. Iphone 12)'
  ]
  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  const handleVirtualChipChange = (chipType: string) => {
    setvirtualChip(chipType === 'virtual')
    if (chipType === 'virtual') updatePlanState({ pickUpChip: false })
  }

  // PICK UP TEXT
  const pickUpVariables = {
    text: mergedArray.slice(2, 6)
  }

  return (
    <Grid
      container
      item
      className={name ? `${name} select-chip-info` : "select-chip-info"}
      xs={12}
    >
      <Grid container item className="chip physicalChip" xs={12}>
        <Grid container item className="checkArea">
          <CheckboxDefault
            checked={!virtualChip}
            onChange={() => handleVirtualChipChange('physical')}
          />
          <Grid item className="textAreaChipInfo">
            <Typography variant="h3" dangerouslySetInnerHTML={{ __html: mergedArray[0] }} />
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: mergedArray[1] }} />
          </Grid>
        </Grid>
        <Grid item className="priceAreaChipInfo">
          <Typography variant="h3" dangerouslySetInnerHTML={{ __html: `+ R$ ${parsedChipPriceNVMO}` }} />
        </Grid>
      </Grid>

      {pickUpInStore && (
        <Collapse in={!virtualChip} sx={{ width: '100%' }}>
          <SelectPickUpChip variables={pickUpVariables} />
        </Collapse>
      )}

      <Divider sx={{ width: '100%' }} />

      <Grid container item className="chip virtualChip" xs={12}>
        <Grid container item className="checkArea">
          <CheckboxDefault
            checked={virtualChip}
            onChange={() => handleVirtualChipChange('virtual')}
          />
          <Grid item className="textAreaChipInfo">
            <Typography variant="h3" dangerouslySetInnerHTML={{ __html: mergedArray[6] }} />
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: mergedArray[7] }} />
          </Grid>
        </Grid>
        <Grid item className="priceAreaChipInfo">
          <Typography variant="h3" dangerouslySetInnerHTML={{ __html: `+ R$ ${parsedEsimPriceNVMO}` }} />
        </Grid>
      </Grid>
    </Grid>
  )
}