import { useEffect, useState } from "react"
import { Grid, Typography } from "@mui/material"
import { usePlan } from "../../../Plans"
import { ISettingsItem } from "../../../Settings"
import { ReactComponent as BnftsWhatsApp } from "../../../assets/icons/bnftsApps/bnftsWhatsApp.svg"
import { ReactComponent as BnftsPhone } from "../../../assets/icons/bnftsApps/bnftsPhone.svg"
import { ReactComponent as BnftsWaze } from "../../../assets/icons/bnftsApps/bnftsWaze.svg"

interface CurrentBnftsProps {
  wpp: boolean
  waze: boolean
}

export default function PlanBnftsApps({ name, variables }: ISettingsItem) {
  const { currentPlan } = usePlan()
  const [currentBnfts, setCurrentBnfts] = useState<CurrentBnftsProps>({ wpp: false, waze: false })
  const defaultTextArray = ['Benefícios do Chip']
  const { text } = variables || {}
  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  useEffect(() => {
    const bnftsApps = currentPlan.aplicativos
    setCurrentBnfts({ wpp: bnftsApps.includes('WhatsApp'), waze: bnftsApps.includes(' Waze') })
  },[currentPlan])

  return (
    <Grid container item className={name ? `${name} plan-bnfts-apps` : "plan-bnfts-apps"} xs={12}>
      <Typography className="planBnftsAppsTitle" variant="h2" dangerouslySetInnerHTML={{ __html: mergedArray[0] }} />
      <Grid container className="bnftsApps">
        <Grid item className="bnftsApp" xs={4} lg={3.9}>
          <BnftsWhatsApp />
          <Typography className="bnftsAppName" variant="body2" dangerouslySetInnerHTML={{ __html: 'Whasapp<br/> Grátis' }} sx={{ fontSize: '16px' }} />
        </Grid>
        <Grid item className="bnftsApp" xs={4} lg={3.9}>
          <BnftsPhone />
          <Typography className="bnftsAppName" variant="body2" dangerouslySetInnerHTML={{ __html: 'Ligações<br/> Infinitas' }} sx={{ fontSize: '16px' }} />
        </Grid>
        {currentBnfts.waze && (
          <Grid item className="bnftsApp" xs={4} lg={3.9}>
            <BnftsWaze />
            <Typography className="bnftsAppName" variant="body2" dangerouslySetInnerHTML={{ __html: 'Waze<br/> Ilimitado' }} sx={{ fontSize: '16px' }} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}