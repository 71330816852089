import { useState } from "react"
import { Button, Grid, Typography } from "@mui/material"
import { ISettingsItem } from "../../../../../Settings"
import SnackbarComponent from "../../../../SnackbarComponent"
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copyIcon.svg'

export default function BoletoCollapse({ name, variables }: ISettingsItem) {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { text = [] } = variables || {}
  const boletoData = JSON.parse(sessionStorage.getItem('boletoData') || '{}')
  const boletoBardCode = boletoData?.boletoBardCode
  const boletoLink = boletoData?.boletoLink

  const handleCopyClick = async () => {
    if (!navigator.clipboard) {
      console.error("Clipboard API não suportada")
      return
    }
  
    try {
      await navigator.clipboard.writeText(boletoBardCode)
      setOpenSnackbar(true)
    } catch (err) {
      console.error("Falha ao copiar!", err)
    }
  }

  const handleDownloadClick = () => {
    if (boletoLink) {
      window.open(boletoLink, '_blank')
    } else {
      console.error("Link do boleto não disponível")
    }
  }

  return (
    <Grid
      container
      className={name ? `${name} boleto-collapse` : "boleto-collapse"}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'column' },
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        pt: { xs: '25px', md: '35px' },
        gap: { xs: '15px', md: '30px' },
      }}
    >
      <Grid
        container
        item
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'space-between' },
          gap: { xs: 2, md: 0 }
        }}
      >
        <Grid item className="stepByStep" sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item className="stepNumber" sx={{ p: '8px 20px', borderRadius: '27px', alignItems: 'center' }}>
            01
          </Grid>
          <Grid item className="stepText" sx={{ flex: '1', pl: 1.5 }}>
            <Typography variant="h6" dangerouslySetInnerHTML={{ __html: text[0] }} sx={{ fontSize: 14 }} />
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: text[1] }} sx={{ fontSize: 12 }} />
          </Grid>
        </Grid>
        <Button
          className="downloadPDFBtn"
          onClick={handleDownloadClick}
          sx={{ textTransform: 'none', p: { xs: '12px 20px', md: '8px 14px', xl: '12px 20px' }, borderRadius: '54px' }}
        >
          Download PDF
        </Button>
      </Grid>
      <Grid
        className="boletoLinkBox"
        item
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          borderRadius: '10px',
          p: { xs: 1.5, md: 2.5 },
          gap: 1
        }}
      >
        <Grid item>
          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: boletoBardCode }} sx={{ wordBreak: 'break-all' }} />
        </Grid>
        <Grid item onClick={handleCopyClick} sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}>
          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: 'COPIAR' }} sx={{ fontSize: { xs: 12 } }} />
          <CopyIcon />
          <SnackbarComponent open={openSnackbar} setOpen={setOpenSnackbar} text="Código copiado!" />
        </Grid>
      </Grid>
    </Grid>
  )
}