import { useEffect, useRef } from 'react'
import { Box, Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { ISettingsItem, useSettings } from '../../../Settings'
import { defaultPlan, Plan, usePlan } from '../../../Plans'
import { useInitReq } from '../../../InitReq'
import { ReactComponent as ChipOnlyIcon } from '../../../assets/icons/chipOnlyIcon.svg'

export default function SelectPlan({ name, variables }: ISettingsItem) {
  const labelRef = useRef<HTMLHeadingElement>(null)
  const label = labelRef.current
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    const adjustLabelFontSize = () => {
      if (!label) return

      const parent = label.parentElement
      if (!parent) return

      let fontSize = 12
      label.style.fontSize = `${fontSize}vw`

      if (mdDown) {
        while (label.scrollWidth > parent.clientWidth && fontSize > 6) {
          fontSize -= 1
          label.style.fontSize = `${fontSize}vw`
        }

        while (label.scrollWidth < parent.clientWidth * 0.7 && fontSize < 14) {
          fontSize += 1
          label.style.fontSize = `${fontSize}vw`
        }
      }

      if (mdUp) label.style.fontSize = `32px`
      if (lgUp) label.style.fontSize = `42px`
      if (xlUp) label.style.fontSize = `58px`
    }

    adjustLabelFontSize()
    window.addEventListener("resize", adjustLabelFontSize)
    return () => window.removeEventListener("resize", adjustLabelFontSize)
  }, [label, mdDown, mdUp, lgUp, xlUp])

  const { pageTitle } = useSettings()
  const { plans, currentPlan, planIndex, chipOnly, portability, updatePlanState, setPortability } = usePlan()
  const { physicalStock, loadingPhysical, loadingVirtual, chipInfo: { chipOnlySale } } = useInitReq()
  const defaultTextArray = [
    'Start',
    'Flexi',
    'Mega',
    'Ultra',
    'Tour',
    'true', // Plan names
    'true', // Chip only
    'flex', // Label h1
    'PRO', // Last plan detail text
    'block' // Last plan detail visibility
  ]
  const { text } = variables || {}

  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  const splitPlan = currentPlan.plano.match(/(\d+)\s*([^\d]+)/) || []
  const splitPlanGB = splitPlan.length > 1 && splitPlan[splitPlan.length - 1].split('')
  const splitPlanG = Array.isArray(splitPlanGB) ? splitPlanGB[0] : ''
  const splitPlanB = Array.isArray(splitPlanGB) ? splitPlanGB[1] : ''

  const handleButtonClick = (index: number, plan: Plan) => {
    setPortability(chipOnly ? true : portability)
    updatePlanState({ currentPlan: plan, planIndex: index, chipOnly: false })
  }

  const chipOnlyClick = () => {
    setPortability(false)
    updatePlanState({ chipOnly: true, currentPlan: defaultPlan, planBonus: false })
  }

  return (
    <Grid container item className={name ? `${name} select-plan` : "select-plan"} xs={12}>
      <Grid container item className="selectArea" xs={12}>
        {plans.length > 0 && !loadingPhysical && !loadingVirtual ? (
          <Grid container item className="containerCarousel">
            {chipOnlySale && physicalStock && mergedArray[6] !== 'false' && (
              <>
                <button
                  onClick={chipOnlyClick}
                  className={chipOnly ? 'active-button' : ''}
                >
                  <ChipOnlyIcon />
                </button>
                <Box className="plansDivider" />
              </>
            )}
            {plans.map((plan, index) => {
              const splitPlanString = plan.plano.match(/(\d+)\s*([^\d]+)/) || []

              return (
                <button
                  key={index}
                  data-button-ligacoes={plan.ligacoes}
                  data-button-nome-plano={plan.nome_plano}
                  onClick={() => handleButtonClick(index, plan)}
                  className={index === planIndex && !chipOnly ? 'active-button' : ''}
                >
                  <h3>{splitPlanString[1]}</h3>
                  <p>{splitPlanString[2]}</p>
                </button>
              )
            })}
            <button
              className="disabledButton"
              disabled={true}
            >
              <h3>100</h3>
              <p>GB</p>
            </button>
            <Grid item sx={{ display: mergedArray[9] }} className="buttonLabel">
              <p>{mergedArray[8]}</p>
            </Grid>
          </Grid>
        ) : (
          <Grid container item className="containerCarousel">
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
          </Grid>
        )}
      </Grid>
      <Grid item className="planTextArea" xs={12}>
        {plans.length > 0 ? (
          chipOnly ? (
            <Grid item className="textAreaChipOnly">
              <h1 ref={labelRef} className="labelChipOnly" style={{ display: mergedArray[7] }}>
                Chip&nbsp;<span>{pageTitle ? pageTitle.split(" ")[0] : 'avulso'}</span>
              </h1>
              <p>Compre agora o chip e ative seu plano depois</p>
            </Grid>
          ) : (
            <>
              {mergedArray[5] === 'true' && (
                <h1 className="labelPlan" style={{ display: mergedArray[7] }}>
                  {mergedArray[plans.findIndex(plan => plan.internet_padrao === currentPlan.internet_padrao)]} {' '}
                </h1>
              )}
              <h3 className="labelPlan">{splitPlan?.[1]}{splitPlanG.toUpperCase()}{splitPlanB.toLowerCase()}</h3>
            </>
          )
        ) : (
          <Skeleton className="skltn-labelPlan" variant="text" width="55%" sx={{ padding: '0.25rem 0' }} />
        )}
      </Grid>
    </Grid>
  )
}
